import React from 'react';
import ProjectCard from './ProjectCard/ProjectCard';

function ProjectsSection() {
  return (
    <section className="projects-section-games">
      <h1 className="section-heading-games project-section-heading-games mb-3">
        OTHER PROJECTS
      </h1>
      <h4 className="project-section-description-games mb-5">
        We craft every project piece by thinking of the end user. That's why our game development
        services make our clients succeed.
      </h4>
      <ProjectCard />
    </section>
  );
}

export default ProjectsSection;
