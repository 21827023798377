import React from 'react';

const CLIENTS_LOGO_DATA = [
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/chillingo-logo.png',
    alt: 'EA Chillingo',
  },
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/homa-logo.png',
    alt: 'Homa',
  },
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/halfbrick-logo.png',
    alt: 'HalfBrick',
  },
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/lionstudios-logo.png',
    alt: 'Lion Studios',
  },
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/pocketgems-logo.png',
    alt: 'Pocket Gems',
  },
  {
    src: 'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/sesamestreet-logo.png',
    alt: 'Sesame Street',
  },
];

function ClientsLogoSection() {
  return (
    <section className="clients-logo-section-games">
      <div className="clients-logo-container-games">
        {CLIENTS_LOGO_DATA.map(({src, alt}) => (
          <div key={src} className="logo-wrapper-games">
            <img className="clients-logo-img-games" src={src} alt={alt} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default ClientsLogoSection;
