import React from 'react';
import headingImage from '../../../../assets/images/connect/live-ops/games_poster.webp';

function HeadingSection() {
  return (
    <section className="live-ops__main-games heading-section-games">
      <div className="heading-container-games">
        <div className="heading-subsection-wrapper-games">
          <div className="heading-subsection-games">
            <p className="heading-description-games mb-lg-4">
              We have been a trusted partner for top publishers since 2008. We provide specialised,
              cost-effective offshore teams for game development. <br />
              <br />
              Recently, we focused on LTV enhancement for leading HCG titles. Presently, we’re
              exploring VR-based training, generative AI game development tools, and immersive
              Roblox experiences.
            </p>
          </div>
        </div>
        <div className="heading-image-container-games">
          <img className="heading-image-games" src={headingImage} alt="heading" />
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
