export const articles = [
  {
    id: 1,
    image: '1.png',
    title: 'Polkacity NFT Game Design',
    link: 'https://polkacity.medium.com/polkacity-nft-gaming-design-32c8c86deb19',
  },
  {
    id: 2,
    image: '2.webp',
    title: '5 Transformative Gen AI Use Cases for Unity Dev - Part 1',
    link: 'https://medium.com/tintash/5-transformative-gen-ai-use-cases-for-unity-development-part-1-code-generation-5df946852d0d',
  },
  {
    id: 3,
    image: '3.webp',
    title: '5 Transformative Gen AI Use Cases for Unity Dev - Part 2',
    link: 'https://medium.com/tintash/5-transformative-gen-ai-use-cases-for-unity-development-part-2-code-debugging-a146a3189810',
  },
  {
    id: 4,
    image: '4.webp',
    title: '5 Transformative Gen AI Use Cases for Unity Dev - Part 3',
    link: 'https://medium.com/tintash/5-transformative-gen-ai-use-cases-for-unity-development-part-3-code-documentation-201aae82265e',
  },
  {
    id: 5,
    image: '5.webp',
    title: '5 Transformative Gen AI Use Cases for Unity Dev - Part 4',
    link: 'https://medium.com/tintash/5-transformative-gen-ai-use-cases-for-unity-development-part-4-code-review-21d2256c7078',
  },
  {
    id: 6,
    image: '6.webp',
    title: '5 Transformative Gen AI Use Cases for Unity Dev - Part 5',
    link: 'https://medium.com/tintash/5-transformative-gen-ai-use-cases-for-unity-development-part-5-technical-design-documentation-556c855de0d9',
  },
  {
    id: 7,
    image: '7.webp',
    title: '5 Reasons Why Your Hyper Casual Games Need LiveOps',
    link: 'https://medium.com/tintash/5-reasons-why-your-hyper-casual-games-need-liveops-cb99e26d0a20',
  },
  {
    id: 8,
    image: '8.webp',
    title: 'Annoyed by Unity’s controversial pricing policy?',
    link: 'https://medium.com/tintash/annoyed-by-unitys-controversial-pricing-policy-5f134b40a8b6',
  },
];
