import React, {useState, useEffect} from 'react';

const LiveOpsCaseStudyCard2 = ({
  id,
  appLogo,
  logos,
  title,
  appImage,
  backgroundImage,
  backgroundImageMobile,
  caseStudyHeading,
  description,
  detail,
  caseStudyLink,
}) => {
  const [currentBackground, setCurrentBackground] = useState(backgroundImage);

  // Dynamically set the background based on screen size
  useEffect(() => {
    const updateBackground = () => {
      if (window.innerWidth <= 768) {
        setCurrentBackground(backgroundImageMobile);
      } else {
        setCurrentBackground(backgroundImage);
      }
    };

    updateBackground(); // Set initial background
    window.addEventListener('resize', updateBackground);

    return () => window.removeEventListener('resize', updateBackground); // Cleanup
  }, [backgroundImage, backgroundImageMobile]);

  return (
    <div
      className="case-study-container"
      style={{
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: 'cover', // Ensure background covers the container
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat', // Prevent background repetition
      }}
    >
      <div className="case-study-image">
        <img src={appImage} alt={title} className="case-study-photo" />
      </div>
      <div className="case-study-content">
        <img src={appLogo} alt={`${title} logo`} className="sesame-logo" />
        <h1 className="case-study-heading">{caseStudyHeading}</h1>
        <p className="case-study-description">{description}</p>
        {logos && (
          <div className="companies-section">
            <img src={logos[1]} alt="Tintash logo" className="company-logo" />
            <span className="separator-x">X</span>
            <img src={logos[0]} alt="Lion studios logo" className="company-logo" />
          </div>
        )}
        {caseStudyLink && (
          <a href={caseStudyLink} target="_blank" className="caseStudyBtn">
            VIEW CASE STUDY
          </a>
        )}
      </div>
      <style jsx>{`
        .case-study-container {
          display: flex;
          background-size: cover;
          background-position: center;
          color: white;
          min-height: 400px;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
        }

        .case-study-heading {
          padding: 0 !important;
          font-size: 2.5rem;
          font-weight: bold;
          margin: 0;
          line-height: 1.2;
          word-wrap: break-word; /* Ensures long words break to the next line */
          overflow-wrap: break-word;
          max-width: 100%; /* Prevents content from overflowing the container */
        }

        .case-study-image {
          flex: 1;
          padding: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .case-study-photo {
          z-index: 1;
          width: 100%;
          min-width: 200px;
          height: auto;
          border-radius: 8px;
        }

        .case-study-content {
          flex: 1;
          padding: 15px 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 24px;
          max-height: 80%;
        }

        .sesame-logo {
          width: 180px;
          max-height: 300px;
        }

        h1 {
          font-size: 2.5rem;
          font-weight: bold;
          margin: 0;
          line-height: 1.2;
        }

        p {
          font-size: 1.1rem;
          line-height: 1.6;
          opacity: 0.9;
          margin: 0;
        }

        .companies-section {
          display: flex;
          align-items: center;
          gap: 24px;
          margin-top: 24px;
        }

        .company-logo {
          height: 100px;
          width: 20%;
        }

        .separator-x {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
        }

        .view-case-study {
          background-color: #ffe81a;
          color: black;
          border: none;
          padding: 12px 24px;
          font-weight: bold;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.2s, transform 0.2s;
          width: fit-content;
          border-radius: 4px;
        }

        .view-case-study:hover {
          background-color: #ffd700;
          transform: translateY(-2px);
        }

        .caseStudyBtn {
          background-color: #ffeb3b;
          color: #343464;
          padding: 0.75rem 1rem;
          text-decoration: none;
          font-family: PoppinsBold;
          font-weight: 900;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          transition: background-color 0.3s ease;
          width: fit-content;
          justify-content: center;
          text-align: center;
        }

        @media (max-width: 1300px) {
          .case-study-container {
            flex-direction: column;
          }
        }

        @media (max-width: 768px) {
          .case-study-container {
            flex-direction: column;
          }

          .case-study-image,
          .case-study-content {
            padding: 20px;
          }

          .case-study-photo {
            max-width: 100%;
          }

          h1 {
             {
              font-size: 1.8rem; /* Adjust font size for smaller screens */
              text-align: center; /* Optional: Center the heading on smaller screens */
            }
          }
        }
      `}</style>
    </div>
  );
};

export default LiveOpsCaseStudyCard2;
