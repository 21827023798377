import React from 'react';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as classes from './ThinkingMentionsSection.module.css';
import arrowRight from './arrowRightt.svg';
import arrowLeft from './arrowLeft.svg';
import Container from '../../../../Shared/Container/Container';
import ThinkingMentionsCard from './ThinkingMentionsCard';
import {articles} from './articlesData';

const ButtonGroup = ({next, previous}) => {
  return (
    <div className="custom-button-group-games">
      <button onClick={previous} className="custom-button-games">
        <img src={arrowLeft} alt="right" className="chevron-icon" />
      </button>
      <button onClick={next} className="custom-button-games">
        <img src={arrowRight} alt="right" className="chevron-icon" />
      </button>
    </div>
  );
};

const responsiveValue = {
  lg: {
    breakpoint: {
      max: Number.MAX_SAFE_INTEGER,
      min: 1201,
    },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 1,
  },
  md: {
    breakpoint: {
      max: 1200,
      min: 769,
    },
    items: 2,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
  sm: {
    breakpoint: {
      max: 768,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
};

function ThinkingMentionsSection({
  bodyClassName,
  carouselResponsive = responsiveValue,
  descriptionClassName,
}) {
  return (
    <section className={classes.section}>
      <Container>
        <div className={clsx(bodyClassName)}>
          <h1 className="section-heading-games project-section-heading-games mb-3">
            THINKING & MENTIONS
          </h1>
          <h4 className="project-section-description-games mb-5">
            Explore articles from our team on game development and innovation, plus recent mentions
            from our clients.
          </h4>
          <div className={classes.postsContainer}>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              customTransition="transform 800ms ease-out"
              transitionDuration={800}
              centerMode={false}
              className=""
              itemClass={classes.carouselItem}
              draggable
              focusOnSelect={false}
              infinite={false}
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={true}
              responsive={carouselResponsive}
              customButtonGroup={<ButtonGroup />}
              slidesToSlide={1}
              swipeable
              ssr
            >
              {articles.map(article => (
                <ThinkingMentionsCard
                  key={article.id}
                  title={article.title}
                  featuredImageLoading="lazy"
                  featuredImageSrc={article.image}
                  link={article.link}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </Container>
      <style jsx>{`
        .custom-button-group-games {
          bottom: 20px;
          right: 20px;
          display: flex;
          align-items: flex-end;
          justify-content: end;
        }

        .custom-button-games {
          background-color: rgba(255, 255, 255, 0);
          border: none;
          color: yellow;
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-left: 10px;
        }

        .chevron-icon-games {
          width: 24px;
          height: 24px;
        }

        .custom-dot-list-style-games {
          display: none !important;
        }

        .carousel-item-padding-games {
          padding: 0 10px;
        }
      `}</style>
    </section>
  );
}

export default ThinkingMentionsSection;
