import React from 'react';

import * as classes from './ProjectCard.module.css';

const PROJECT_DATA = [
  {
    title: 'GENOPETS',
    category: 'Web3.0',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_genopets.webp')
      .default,
  },
  {
    title: 'NAIL SALON',
    category: 'HybridCasual',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_nail_salon.webp')
      .default,
  },
  {
    title: 'BEAT BUGS',
    category: 'Edutainment',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_beats_bug.webp')
      .default,
  },
  {
    title: 'STICK CRICKET',
    category: 'Casual ',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_stick_cricket.webp')
      .default,
  },
  {
    title: 'POLKA CITY',
    category: 'Metaverse',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_polka_city.webp')
      .default,
  },
  {
    title: 'SESAME STREET',
    category: 'Edutainment',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_sesame.webp').default,
  },
  {
    title: 'CARE BEARS',
    category: 'Edutainment',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_care_bears.webp')
      .default,
  },
  {
    title: 'GAMERIZE',
    category: 'Edutainment',
    imageSrc: require('../../../../../assets/images/connect/live-ops/project_gamerize.webp')
      .default,
  },
];

function ProjectCard() {
  let logoClass = classes.logo;

  return (
    <article className={classes.card}>
      <div style={{flexDirection: 'row', justifyItems: 'center'}}>
        <div className="project-container" style={{marginBottom: '40px'}}>
          {PROJECT_DATA.map(project => (
            <div className="project-card">
              <img className={logoClass} src={`${project.imageSrc}`} alt="logo" />
              <h1 className="project-heading" style={{padding: 0, marginBottom: '12px'}}>
                {project.title}
              </h1>
              <h3 className="project-description">{project.category}</h3>
            </div>
          ))}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <a
            className="button-text"
            href="https://www.tintash.com/portfolio/"
            style={{color: '#343464'}}
          >
            SEE FULL PORTFOLIO
          </a>
        </div>
      </div>
    </article>
  );
}

export default ProjectCard;
