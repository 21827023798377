import React from 'react';
import gameDevIcon from '../../../../assets/images/connect/live-ops/logo_game_development.svg';
import liveopsIcon from '../../../../assets/images/connect/live-ops/logo_liveops.svg';
import coDevelopIcon from '../../../../assets/images/connect/live-ops/logo_co_development.svg';
import artAnimationIcon from '../../../../assets/images/connect/live-ops/logo_art_animation.svg';
import * as classes from './CaseStudyCard/CaseStudyCard.module.css';

const SOLUTION_CARD_DATA = [
  {
    id: 1,
    icon: gameDevIcon,
    heading: 'AI ENABLED GAME DEVELOPMENT',
  },
  {
    id: 2,
    icon: liveopsIcon,
    heading: 'LIVEOPS',
  },
  {
    id: 3,
    icon: coDevelopIcon,
    heading: 'CO - DEVELOPMENT',
  },
  {
    id: 4,
    icon: artAnimationIcon,
    heading: 'ART & ANIMATION',
  },
];

function SolutionsSection() {
  return (
    <section className="solutions-section-games">
      <div className="details-wrapper-games ">
        <h1 className="section-heading-games solution-heading-games">
          <span style={{color: '#2E8EFF'}}>Our Game development</span> Solutions
        </h1>
        <h1 className="solution-description-games">
          Unlock the power of seamless game creation with our comprehensive suite of development
          services. From initial ideation to polished execution.
        </h1>
        <div className={classes.row}>
          {SOLUTION_CARD_DATA.map(card => (
            <div
              className="d-flex flex-column"
              key={card.id}
              style={{flex: 1, marginLeft: '24px', marginRight: '24px'}}
            >
              <div className="detail-card-games">
                <img className="detail-icon-games" src={card.icon} alt="solution icon" />
                <h2 className="description-heading-games">{card.heading}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SolutionsSection;
