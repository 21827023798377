import React from 'react';
import * as classes from './CaseStudyCard/CaseStudyCard.module.css';
import LiveOpsCaseStudyCard2 from './CaseStudyCard/LiveOpsCaseStudyCard2';

const CASE_STUDY_DATA = [
  {
    id: 'Sesame',
    isliveOps: false,
    appLogo: require('../../../../assets/images/connect/live-ops/logo_sesame_street.svg').default,
    caseStudyLink: 'https://tintash.com/portfolio/sesame/',
    title: (
      <h2 className="case-study-details-heading">
        <span className="featured-text">FEATURED</span>
        <span className="full-text"> PORTING CASE STUDY</span>
        <span className="short-text">PORTING CASE STUDY</span>
      </h2>
    ),
    appImage: require('../../../../assets/images/connect/live-ops/app_sesame_street.webp').default,
    backgroundImage: require('../../../../assets/images/connect/live-ops/sesame.webp').default,
    backgroundImageMobile: require('../../../../assets/images/connect/live-ops/sesame-mobile.webp')
      .default,
    caseStudyHeading: 'The most loved titles published by Sesame',
    description: (
      <p className="case-study-details-description">
        Sesame engaged Tintash to work on two interactive storybook games Monster at the End of this
        Book, and Another Monster at the End of this Book The games were previously built in Cocos2D
        and Tintash converted them to Unity.  Reviewed and updated the legacy technology for
        optimized performance on the latest devices.
      </p>
    ),
  },
  {
    id: 'Nail Salon',
    isliveOps: false,
    appLogo:
      'https://tintash-task-bucket.s3.us-east-1.amazonaws.com/main/liveOps/nail-salon-logo.png',
    ratingImage: require('../../../../assets/images/connect/live-ops/nail_salon_rating.svg')
      .default,
    downloads: '80M+ DOWNLOADS',
    title: (
      <h2 className="case-study-details-heading live-ops-heading">
        <span className="featured-text">FEATURED</span> LIVEOPS CASE STUDY
      </h2>
    ),
    heading: (
      <h2
        className={`case-study-details-heading live-ops-heading ${classes.mobileHidden}`}
        style={{fontSize: '20px', lineHeight: '22px', marginTop: '16px'}}
      >
        <span style={{color: '#2E8EFF'}}>NAIL SALON : </span> IDEATION TO LAUNCH AND LIVEOPS
      </h2>
    ),
    appImage: require('../../../../assets/images/connect/live-ops/nail_salon_app_images.webp')
      .default,
    backgroundImage: require('../../../../assets/images/connect/live-ops/nail-salon.webp').default,
    backgroundImageMobile:
      require('../../../../assets/images/connect/live-ops/nail-salon-mobile.webp').default,
    caseStudyHeading: 'Nail Salon : Ideation to launch and liveops',
    logos: [
      require('../../../../assets/images/connect/live-ops/logo_lion_studio.svg').default,
      require('../../../../assets/images/connect/live-ops/tintash_logo.svg').default,
    ],
    description: (
      <>
        <div className="metrics-section">
          <h2 className="metrics-title">LiveOps Outcome</h2>

          <div className="metrics-grid">
            <div className="metric">
              <span className="metric-value">+85%</span>
              <span className="metric-label">LTV</span>
            </div>
            <div className="separator" />

            <div className="metric">
              <span className="metric-value">+6%</span>
              <span className="metric-label">RRDI</span>
            </div>
            <div className="separator" />

            <div className="metric">
              <span className="metric-value">+3%</span>
              <span className="metric-label">RRD3</span>
            </div>
            <div className="separator" />

            <div className="metric">
              <span className="metric-value">80M+</span>
              <span className="metric-label">Downloads</span>
            </div>
          </div>
        </div>
        <style jsx>{`
          .card-container {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
            border-radius: 24px;
            padding: 40px;
            color: white;
          }

          .content-wrapper {
            display: flex;
            gap: 40px;
            align-items: center;
          }

          .left-section {
            flex: 0 0 auto;
          }

          .avatar-card {
            background: #e6f7ff;
            border-radius: 16px;
            padding: 20px;
            width: 320px;
            height: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .avatar-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .right-section {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
          }

          .logo-section {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .nail-salon-logo {
            background: linear-gradient(135deg, #4a90e2, #357abd);
            padding: 8px 16px;
            border-radius: 12px;
            display: flex;
            gap: 4px;
          }

          .logo-text {
            font-size: 24px;
            font-weight: bold;
            color: white;
          }

          .logo-text.purple {
            color: #b19cd9;
          }

          .title {
            font-size: 36px;
            font-weight: bold;
            margin: 0;
          }

          .metrics-section {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .metrics-title {
            font-size: 24px;
            margin: 0;
            color: #ffffff;
          }

          .metrics-grid {
            display: flex;
            align-items: center;
            gap: 24px;
          }

          .metric {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .metric-value {
            font-size: 32px;
            font-weight: bold;
          }

          .metric-label {
            font-size: 16px;
            color: #ffffff;
          }

          .separator {
            width: 2px;
            height: 40px;
            background: #ffffff;
          }

          @media (max-width: 1250px) {
            .metrics-grid {
              flex-wrap: wrap;
            }
          }

          @media (max-width: 768px) {
            .content-wrapper {
              flex-direction: column;
            }

            .avatar-card {
              width: 100%;
              height: 240px;
            }

            .metrics-grid {
              flex-wrap: wrap;
            }

            .title {
              font-size: 28px;
            }

            .metric-value {
              font-size: 24px;
            }
          }
        `}</style>
      </>
    ),
  },
  {
    id: 'Gamerize',
    isliveOps: false,
    appLogo: require('../../../../assets/images/connect/live-ops/logo_gemarize.webp').default,
    caseStudyLink: 'https://tintash.com/portfolio/gamerize/',
    title: (
      <h2 className="case-study-details-heading">
        <span className="featured-text">FEATURED</span>
        <span className="full-text"> EDUCATIONAL GAME CASE STUDY</span>
        <span className="short-text">EDUTAINMENT CASE STUDY</span>
      </h2>
    ),
    appImage: require('../../../../assets/images/connect/live-ops/app_gemarize.webp').default,
    backgroundImage: require('../../../../assets/images/connect/live-ops/gamerize.webp').default,
    backgroundImageMobile:
      require('../../../../assets/images/connect/live-ops/gamerize-mobile.webp').default,
    caseStudyHeading: 'The Funnest Dictionary in the World',
    description: (
      <p className="case-study-details-description">
        Build Gamerize Dictionary, a gamified language learning experience designed to help young
        Japanese learners improve vocabulary. Gamerize Dictionary had over 2000 organic users, 500
        daily sessions, and 10 check-ins on average per day.
      </p>
    ),
    detail: (
      <h2 className="case-study-details-text">
        Gamerize Dictionary had over 2000 organic users, 500 daily sessions, and 10 check-ins on
        average per day.
      </h2>
    ),
  },
  {
    id: 'Stick-Cricket',
    isliveOps: false,
    appLogo: require('../../../../assets/images/connect/live-ops/logo_street_cricket.svg').default,
    title: (
      <h2 className="case-study-details-heading">
        <span className="featured-text">FEATURED</span>
        <span className="full-text"> FULL CYCLE DEV CASE STUDY</span>
        <span className="short-text"> FULL CYCLE DEVELOPMENT</span>
      </h2>
    ),
    appImage: require('../../../../assets/images/connect/live-ops/app_stick_cricket.webp').default,
    backgroundImage: require('../../../../assets/images/connect/live-ops/stick-cricket.webp')
      .default,
    backgroundImageMobile:
      require('../../../../assets/images/connect/live-ops/stick-cricket-mobile.webp').default,
    caseStudyHeading: 'World’s most famous cricket game by Stick Sports',
    description: (
      <p className="case-study-details-description">
        <br />
        Tintash developed 5 titles: <br />
        <span className="case-study-images">
          <div className="games-container">
            <div className="game-logo">
              <img
                src={require('../../../../assets/images/stickcricket/sc-dtw.png').default}
                alt="Stick Cricket: Dominate the World"
                className="logo-image"
              />
            </div>
            <div className="game-logo">
              <img
                src={require('../../../../assets/images/stickcricket/sc-sl.png').default}
                alt="Super League"
                className="logo-image"
              />
            </div>
            <div className="game-logo">
              <img
                src={require('../../../../assets/images/stickcricket/sc-spl.png').default}
                alt="SPL Premier League"
                className="logo-image"
              />
            </div>
            <div className="game-logo">
              <img
                src={require('../../../../assets/images/stickcricket/sc-partnership.png').default}
                alt="Stick Cricket Partnerships"
                className="logo-image"
              />
            </div>
            <div className="game-logo">
              <img
                src={require('../../../../assets/images/stickcricket/sc-vr.png').default}
                alt="Stick Cricket"
                className="logo-image"
              />
            </div>
            {/* CSS styles */}
            <style jsx>{`
              .games-container {
                display: flex;
                flex-wrap: wrap; /* Allow items to wrap on smaller screens */
                justify-content: center; /* Center the images */
                gap: 16px; /* Add space between images */
                margin: 20px 0;
              }

              .game-logo {
                flex: 1 1 calc(20% - 16px); /* Responsive size, 20% width minus gap */
                max-width: 200px; /* Limit maximum width */
                box-sizing: border-box; /* Include padding in width calculation */
              }

              .logo-image {
                width: 100%; /* Make image take full container width */
                height: auto; /* Maintain aspect ratio */
                object-fit: contain; /* Ensure the whole image fits inside */
              }

              @media (max-width: 768px) {
                .game-logo {
                  flex: 1 1 calc(50% - 16px); /* 2 columns on smaller screens */
                }
              }

              @media (max-width: 1250px) {
                .game-logo {
                  flex: 1 1 calc(33.3% - 16px); /* 3 columns on smaller screens */
                }
              }

              @media (max-width: 480px) {
                .game-logo {
                  flex: 1 1 100%; /* 1 column on very small screens */
                }
              }
            `}</style>
          </div>
        </span>
      </p>
    ),
    detail: (
      <div className={classes.statsRow}>
        <img
          src={
            require('../../../../assets/images/connect/live-ops/stick_cricket_rating_image.svg')
              .default
          }
          alt="logo"
          className={classes.statsLogo}
        />
        <img
          src={
            require('../../../../assets/images/connect/live-ops/stick_cricket_download_image.svg')
              .default
          }
          alt="logo"
          className={classes.statsLogo}
        />
      </div>
    ),
  },
];

function CaseStudySection() {
  return (
    <section className="case-study-section">
      {CASE_STUDY_DATA.map(caseStudy => (
        <div key={caseStudy.id} style={{marginBottom: '48px'}}>
          <LiveOpsCaseStudyCard2 {...caseStudy} />
        </div>
      ))}
    </section>
  );
}

export default CaseStudySection;
