// extracted by mini-css-extract-plugin
export var arrow = "ThinkingMentionsCard-module--arrow--IY1io";
export var body = "ThinkingMentionsCard-module--body--6zSyC";
export var card = "ThinkingMentionsCard-module--card--V4JxD";
export var featuredImage = "ThinkingMentionsCard-module--featured-image--Ui4Y7";
export var header = "ThinkingMentionsCard-module--header--i2ogO";
export var logo = "ThinkingMentionsCard-module--logo--4yndE";
export var logoContainer = "ThinkingMentionsCard-module--logo-container--+IOxj";
export var mainContainer = "ThinkingMentionsCard-module--mainContainer--6zTf7";
export var readMoreBtn = "ThinkingMentionsCard-module--readMoreBtn--v9ZBM";
export var rectLogo = "ThinkingMentionsCard-module--rect-logo--LXnNj";
export var roundLogo = "ThinkingMentionsCard-module--round-logo--jHhQh";
export var title = "ThinkingMentionsCard-module--title--+p8P1";