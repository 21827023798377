import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
//import FaLongArrowAltRight from 'react-icons/fa';
import * as classes from './ThinkingMentionsCard.module.css';

function ThinkingMentionsCard({title, link, featuredImageSrc, featuredImageLoading}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "blog-posts/featured-images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `blog-posts/featured-images/${featuredImageSrc}` === node.relativePath
      ),
    [data, featuredImageSrc]
  );
  return (
    <div className={classes.mainContainer}>
      <article className={`${classes.card}`}>
        <header className={classes.header}>
          <div className={classes.featuredImage}>
            <Img
              className={classes.featuredImage}
              imgStyle={{objectFit: 'cover'}}
              placeholderStyle={{objectFit: 'cover'}}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={match.node.childImageSharp.fluid}
              alt={title}
              fadeIn={false}
              loading={featuredImageLoading}
            />
          </div>
        </header>
        <div className={classes.body}>
          <div className={classes.title}>{title}</div>
          <a className={classes.readMoreBtn} href={link} target="_blank" rel="noopener noreferrer">
            Read More
          </a>
        </div>
      </article>
    </div>
  );
}

ThinkingMentionsCard.propTypes = {
  companyName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  featuredImageSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isLogoRound: PropTypes.bool,
  featuredImageLoading: PropTypes.oneOf(['lazy', 'eager']),
};

ThinkingMentionsCard.defaultProps = {
  isLogoRound: false,
  featuredImageLoading: 'eager',
};

export default ThinkingMentionsCard;
