// extracted by mini-css-extract-plugin
export var appLogo = "CaseStudyCard-module--appLogo--oGpEp";
export var appScreenshot = "CaseStudyCard-module--appScreenshot--o0MJF";
export var banner = "CaseStudyCard-module--banner--8sQTq";
export var banner_container = "CaseStudyCard-module--banner_container--S6arS";
export var banner_img = "CaseStudyCard-module--banner_img--ObFsF";
export var boldStatText = "CaseStudyCard-module--boldStatText--XQlpl";
export var box = "CaseStudyCard-module--box--lTSae";
export var card = "CaseStudyCard-module--card--o7+co";
export var cardHeading = "CaseStudyCard-module--cardHeading--XclEY";
export var cardRow = "CaseStudyCard-module--cardRow--oRo8k";
export var cardText = "CaseStudyCard-module--cardText--MYCSP";
export var cardTextLight = "CaseStudyCard-module--cardTextLight--7z4JI";
export var caseStudyBtn = "CaseStudyCard-module--caseStudyBtn--xR5D+";
export var column = "CaseStudyCard-module--column---eKjM";
export var contentColumn = "CaseStudyCard-module--contentColumn--H2x64";
export var description = "CaseStudyCard-module--description--xtd+n";
export var descriptionBox = "CaseStudyCard-module--descriptionBox--bN84U";
export var description_container = "CaseStudyCard-module--description_container--qU1n1";
export var detailBox = "CaseStudyCard-module--detailBox--zWMTG";
export var firstColumn = "CaseStudyCard-module--firstColumn--mzXiA";
export var firstRow = "CaseStudyCard-module--firstRow--eEI3r";
export var fullScreenHidden = "CaseStudyCard-module--fullScreenHidden--E36PM";
export var heading = "CaseStudyCard-module--heading--UhTH-";
export var icon = "CaseStudyCard-module--icon--wBU+M";
export var iconRow = "CaseStudyCard-module--iconRow--OjEA3";
export var image = "CaseStudyCard-module--image--IchkQ";
export var imageBox = "CaseStudyCard-module--imageBox--+yRy2";
export var imageRow = "CaseStudyCard-module--imageRow--PtZZE";
export var liveOpColumn = "CaseStudyCard-module--liveOpColumn--ecUKz";
export var logo = "CaseStudyCard-module--logo--vGjB+";
export var logoBox = "CaseStudyCard-module--logoBox--51SiS";
export var logoContainer = "CaseStudyCard-module--logoContainer--DvG+A";
export var logo_container = "CaseStudyCard-module--logo_container--PgDF1";
export var logo_small = "CaseStudyCard-module--logo_small--Ipo6A";
export var mobileHidden = "CaseStudyCard-module--mobileHidden--Mto41";
export var mobileStatsBoxes = "CaseStudyCard-module--mobileStatsBoxes--YHJw+";
export var mobileTop = "CaseStudyCard-module--mobileTop--xb-xg";
export var name = "CaseStudyCard-module--name--tIh3x";
export var noPadding = "CaseStudyCard-module--noPadding--QXByX";
export var position = "CaseStudyCard-module--position--oFcKR";
export var ratingCard = "CaseStudyCard-module--ratingCard--8JRoM";
export var ratingCardWrapper = "CaseStudyCard-module--ratingCardWrapper--T96to";
export var ratingSection = "CaseStudyCard-module--ratingSection--5I5nM";
export var responsiveImage = "CaseStudyCard-module--responsiveImage--xsWT9";
export var row = "CaseStudyCard-module--row--RRO-O";
export var secondRow = "CaseStudyCard-module--secondRow--IBv9r";
export var statsBoxes = "CaseStudyCard-module--statsBoxes--l9Xvx";
export var statsCard = "CaseStudyCard-module--statsCard--tyaYK";
export var statsColumn = "CaseStudyCard-module--statsColumn--QjiyO";
export var statsContainer = "CaseStudyCard-module--statsContainer--eEUz3";
export var statsLogo = "CaseStudyCard-module--statsLogo--41MqB";
export var statsRow = "CaseStudyCard-module--statsRow--6Wvel";
export var statsSection = "CaseStudyCard-module--statsSection--BKW2g";
export var statsText = "CaseStudyCard-module--statsText--mb1Mw";
export var storeCard = "CaseStudyCard-module--storeCard--DZhLi";
export var subRow = "CaseStudyCard-module--subRow--tMxux";
export var testimonial = "CaseStudyCard-module--testimonial--WiRwa";
export var text = "CaseStudyCard-module--text--o6+wH";
export var textBox = "CaseStudyCard-module--textBox--k-1pd";
export var title = "CaseStudyCard-module--title--frfVW";
export var titleBox = "CaseStudyCard-module--titleBox--VwcbR";
export var values = "CaseStudyCard-module--values--7IGNz";