import React from 'react';
import {Helmet} from 'react-helmet';
import '../../../assets/css/connect.css';
import '../../../assets/css/live-ops-games.css';
import HeadingSection from './Section/HeadingSection';
import ClientsLogoSection from './Section/ClientLogoSection';
import SolutionsSection from './Section/SolutionsSection';
import CaseStudySection from './Section/CaseStudySection';
import ProjectsSection from './Section/ProjectsSection';
import ThinkingMentionsSection from './Section/ThinkingMentionsSection/ThinkingMentionsSection';
import StudioNavbar from '../../Shared/StudioNavbar';

function GamesLiveOpsTemplate() {
  return (
    <div className="games-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Games | Tintash</title>
      </Helmet>
      <StudioNavbar studioName="Games Studio" />
      <HeadingSection />
      <ClientsLogoSection />
      <CaseStudySection />
      <ProjectsSection />
      <SolutionsSection />
      <ThinkingMentionsSection />
    </div>
  );
}

export default GamesLiveOpsTemplate;
