// extracted by mini-css-extract-plugin
export var banner = "ProjectCard-module--banner--mK7sv";
export var banner_container = "ProjectCard-module--banner_container--sqyAI";
export var banner_img = "ProjectCard-module--banner_img--LoJxs";
export var card = "ProjectCard-module--card--cKOS+";
export var description = "ProjectCard-module--description--Wvno1";
export var description_container = "ProjectCard-module--description_container--DvRf4";
export var expertiseCard = "ProjectCard-module--expertise-card--4SA-E";
export var expertiseCardCount = "ProjectCard-module--expertise-card-count--WvuG7";
export var expertiseContainer = "ProjectCard-module--expertise-container--Z1bEt";
export var expertiseDescription = "ProjectCard-module--expertise-description--O1lqt";
export var expertiseHeading = "ProjectCard-module--expertise-heading---f6gg";
export var expertiseSection = "ProjectCard-module--expertise-section--enzaX";
export var heading = "ProjectCard-module--heading--Fke2S";
export var logo = "ProjectCard-module--logo--7W3iO";
export var logo_container = "ProjectCard-module--logo_container--bkQf9";
export var logo_small = "ProjectCard-module--logo_small--ooHsv";
export var name = "ProjectCard-module--name--+sKyI";
export var position = "ProjectCard-module--position--IUjYA";
export var testimonial = "ProjectCard-module--testimonial--xZzcP";
export var title = "ProjectCard-module--title--vmjd5";
export var values = "ProjectCard-module--values--Q035h";